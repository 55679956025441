
















import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'

@Component({
  components: {
  }
})

export default class Slider extends Vue {
  private width = 0
  private range = 0
  private valPerPx = 0
  private pxPerVal = 0

  mounted (): void {
    this.placeMarkers()
    window.addEventListener('resize', this.placeMarkers)
  }

  markerPos (marker: number): number {
    var initial = 1 * this.pxPerVal * marker
    var percentAcross = (initial / this.width)
    var normalise = (initial - this.pxPerVal) + (this.pxPerVal * percentAcross)
    return normalise
  }

  placeMarkers (): void {
    const sliderElement = (this.$refs.sliderRef as HTMLElement)
    this.width = sliderElement.clientWidth
    this.range = this.max - this.min + 1
    this.valPerPx = this.range / this.width
    this.pxPerVal = 1 / this.valPerPx
  }

  @Prop({ required: true })
  private readonly min!: number;

  @Prop({ required: true })
  private readonly max!: number;

  @PropSync('defValue', { required: true })
  private readonly sliderValue!: number;

  @Prop({ required: true })
  private readonly markers!: number[];

  @Prop({ required: true })
  private readonly units!: string;
}

