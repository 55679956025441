
























import { Mixins, Vue, Component, Prop } from 'vue-property-decorator'
import Card from './BaseCard.vue'
import Icon from '../ui/Icon.vue'

@Component({
  components: {
    Card,
    Icon
  }
})
export default class FeatureList extends Mixins(Vue) {
  @Prop()
  private readonly icon!: string

  @Prop()
  private readonly title!: string

  @Prop()
  private readonly price!: number

  @Prop()
  private readonly changePer!: string

  @Prop()
  private readonly total!: number
}
