
































import { Vue, Component, Watch } from 'vue-property-decorator'
import SideBySide from '@/components/layout/SideBySide.vue'
import Slider from '@/components/Slider.vue'
import { API, ServiceManager } from '@/app_code/services'
import ServiceType from '@/app_code/services/ServiceTypes'
import { RuleResultModel } from '@/app_code/models/RuleResultModel'

@Component({
  components: {
    SideBySide,
    Slider
  }
})

export default class CustomQuollPackageBuilder extends Vue {
  private calcRules: RuleResultModel[] | null = null
  private api = ServiceManager.Require<API>(ServiceType.API)

  public total = 0

  /*
  get total (): number {
    return (this.components.GenericCredit.CalculatedPrice * 5.50) * this.components.User.CalculatedPrice
  }
  */

  private components: {[key: string]: any} = {
    GenericCredit: {
      Qty: 10,
      Ruleset: null,
      CalculatedPrice: 0
    },
    User: {
      Qty: 1,
      Rulset: null,
      CalculatedPrice: 0
    }
  }

  mounted (): void {
    this.total = (this.components.GenericCredit.Qty * 5.50) * this.components.User.Qty
  }

  @Watch('components.GenericCredit.Qty')
  @Watch('components.User.Qty')
  recalculate (): void {
    this.total = (this.components.GenericCredit.Qty * 5.50) * this.components.User.Qty
    console.log(this.total)
  }
}

