
































import { Vue, Component, Watch } from 'vue-property-decorator'
import SideBySide from '@/components/layout/SideBySide.vue'
import Slider from '@/components/Slider.vue'
import { API, ServiceManager } from '@/app_code/services'
import ServiceType from '@/app_code/services/ServiceTypes'
import { RuleResultModel } from '@/app_code/models/RuleResultModel'

@Component({
  components: {
    SideBySide,
    Slider
  }
})

export default class CustomPackageBuilder extends Vue {
  private calcRules: RuleResultModel[] | null = null
  private api = ServiceManager.Require<API>(ServiceType.API)

  get total (): number {
    return this.components.GenericCredit.CalculatedPrice + this.components.User.CalculatedPrice
  }

  private components: {[key: string]: any} = {
    GenericCredit: {
      Qty: 10,
      Ruleset: null,
      CalculatedPrice: 0
    },
    User: {
      Qty: 1,
      Rulset: null,
      CalculatedPrice: 0
    }
  }

  mounted (): void {
    this.getPricingRules().then(() => {
      this.recalculate()
    })
  }

  @Watch('components.GenericCredit.Qty')
  @Watch('components.User.Qty')
  recalculate (): void {
    var keys = Object.keys(this.components)
    var total = 0

    for (var i = 0; i < keys.length; i++) {
      var key = keys[i]
      var component = this.components[key]
      this.recalculateComponent(component)
      total += component.CalculatedPrice
    }

    // this.1total = total
  }

  recalculateComponent (component: any): void {
    var total = 0
    if (component.Ruleset?.isMarginal) {
      for (var i = 0; i < component.Ruleset.rules.length; i++) {
        var rule = component.Ruleset.rules[i]
        var qtyInRule = Math.max(0, Math.min(rule.maxQty - rule.minQty, component.Qty - rule.minQty))
        total += qtyInRule * rule.rate
      }
    } else {
      for (var j = 0; j < component.Ruleset?.rules.length; j++) {
        var rule2 = component.Ruleset.rules[j]
        if (component.Qty > rule2.maxQty) continue
        total = component.Qty * rule.rate
        break
      }
    }
    component.CalculatedPrice = total
  }

  getPricingRules (): Promise<void> {
    return new Promise((resolve, reject) => {
      this.api.PaymentAPI.GetPricingCalculatorRules()
        .then(data => {
          var keys = Object.keys(this.components)
          for (var i = 0; i < keys.length; i++) {
            var key = keys[i]
            var component = this.components[key]
            component.Ruleset = data?.find(rs => rs.itemTypeName === key)
          }
          resolve()
        }).catch(reject)
    })
  }
}

