


























































































































import { Vue, Component, Prop } from 'vue-property-decorator'

import { CarouselBreakpoint } from '@/app_code/CarouselBreakpoint'

import Cycler from './Cycler.vue'
import CyclerItem from './CyclerItem.vue'
import PricingCard from '../cards/PricingCard.vue'

@Component({
  components: {
    Cycler,
    CyclerItem,
    PricingCard
  }
})
export default class PricingCardCycler extends Vue {
  private breakpoints: CarouselBreakpoint[] = [
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 1
      }
    },
    {
      breakpoint: 720,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 4
      }
    }
  ]

  mounted () {
    // this.loadListings();
  }
}
