






import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Icon extends Vue {
  @Prop({ required: true })
  private readonly icon!: string

  @Prop({ default: '' })
  private readonly colour!: string

  @Prop({ default: '' })
  private readonly pack!: string

  @Prop()
  private readonly block!: boolean

  get fileName (): string {
    const parts = [this.pack]

    if (this.colour) parts.push(this.colour)

    parts.push(`${this.icon}.svg`)

    return parts.join('/')
  }
}
